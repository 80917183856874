import React, { FC, Fragment, memo, useMemo } from 'react'

import HeaderSection from './header-section'
import SearchbarSection from './searchbar-section'
import BestDestinationsSection from './best-destinations-section/index'
import VacationRegionsSection from './vacation-regions-section'
import TipsSection from './tips-section'
import RegionOverviewSection from './region-overview-section'
import AccommodationSection from './accommodation-section'
import ReviewsSection from './reviews-section'
import TopOffersSection from './top-offers-section'
import HolidayIdeasSection from './holiday-ideas'
import NewsLetter from './news-letter'
import FooterSection from './footer-section'
import BreadcrumbSection from './breadcrumb-section'
import TopRecommendationsSection from './top-recommendations-section'
import {
    HeroSectionTransport,
    HolidayIdeasSectionTransport,
    PopularDestinationsSectionTransport,
    PopularFiltersSectionTransport,
    RegionLandingPageStaticTransport,
    RegionsSectionTransport,
    TypicalAccommodationsSectionTransport,
} from '../../transports/region-landing-page-transport'
import ListingSectionTransport from '../../transports/common/listing-section-transport'
import { Container, InnerContainer } from './landing-page-styles'
import PopularObjectRedesign from './popular-object-redesign'
import PopularFilterListingsSection from './popular-filter-listings-section'
import { getOrganizationSchemaDetail } from '../../utils/miscellaneous-utils'
import { BaseSearchbarHistoryItem } from '../../redux/searchbar/types'
import { CallbackFunction } from '../../common/types'
import BreadcrumbTransport from '../../transports/common/breadcrumb-transport'
import { useTranslation } from '../../hooks/locale'
import { RegionDirectoryPageTransport } from '../../transports/directory-transport'
import DirectorySection from '../directory-page/directory-section'
import StickyFooter from './sticky-footer'

interface LandingPageProps {
    isLoggedIn: boolean
    hero: HeroSectionTransport
    recommendedObjects: ListingSectionTransport | null
    staticData: RegionLandingPageStaticTransport
    popularDestinationsSection: PopularDestinationsSectionTransport | null
    topRatedObjectsSection: ListingSectionTransport | null
    topOfferListingsSection: ListingSectionTransport | null
    typicalAccommodationsSection: TypicalAccommodationsSectionTransport | null
    popularFilterListingsSections: ListingSectionTransport[]
    popularFiltersSection: PopularFiltersSectionTransport | null
    regionsSection: RegionsSectionTransport | null
    holidayIdeasSection: HolidayIdeasSectionTransport | null
    locationName: string
    addToHistory: (historyItem: BaseSearchbarHistoryItem) => void
    searchFunction: CallbackFunction
    firstName?: string
    url: string
    sitePathname?: string
    breadcrumbs: BreadcrumbTransport[]
    directoryPageResponse: RegionDirectoryPageTransport | null
}

const LandingPage: FC<LandingPageProps> = ({
    isLoggedIn,
    hero,
    recommendedObjects,
    staticData,
    popularDestinationsSection,
    topRatedObjectsSection,
    topOfferListingsSection,
    typicalAccommodationsSection,
    popularFilterListingsSections,
    popularFiltersSection,
    regionsSection,
    holidayIdeasSection,
    locationName,
    addToHistory,
    searchFunction,
    firstName,
    url,
    sitePathname,
    breadcrumbs,
    directoryPageResponse,
}) => {
    const { headerSection, heroSection, newsLetterSection, footerSection, tips, faqs, regionOverviewList } = staticData
    const { t } = useTranslation()
    const organizationSchemaHtml = useMemo(() => getOrganizationSchemaDetail(t), [])

    const hasDirectorySectionWithLinks = directoryPageResponse?.sections?.some(section => section.links.length > 0)

    return (
        <Fragment>
            <script type="application/ld+json" dangerouslySetInnerHTML={organizationSchemaHtml} />
            <HeaderSection
                firstName={firstName}
                isLoggedIn={isLoggedIn}
                url={url}
                headerSection={headerSection}
                sitePathname={sitePathname}
                showVacationIdeasNavButton
            />
            <main id="region-landing-page-main">
                <Container>
                    <SearchbarSection
                        hero={hero}
                        regionTitle={topOfferListingsSection?.heading}
                        count={recommendedObjects?.totalCount ?? 0}
                        locationName={locationName}
                        addToHistory={addToHistory}
                        searchFunction={searchFunction}
                        url={url}
                        heroSection={heroSection}
                        logo={headerSection.navigationSectionResponse?.navigation?.logo}
                    />
                    <InnerContainer>
                        {!!breadcrumbs && breadcrumbs.length > 0 && (
                            <BreadcrumbSection breadcrumbs={breadcrumbs} url={url} />
                        )}
                        {!!popularDestinationsSection && popularDestinationsSection.regions.length > 0 && (
                            <BestDestinationsSection popularDestinationsSection={popularDestinationsSection} />
                        )}
                        {!!recommendedObjects && recommendedObjects.objects.length > 0 && (
                            <TopRecommendationsSection
                                topRecommendedSection={recommendedObjects}
                                url={url}
                                locationName={locationName}
                                offerHeading={topOfferListingsSection?.heading}
                            />
                        )}
                        {!!topRatedObjectsSection && topRatedObjectsSection.objects.length > 0 && (
                            <ReviewsSection topRatedObjectsSection={topRatedObjectsSection} />
                        )}
                        {!!topOfferListingsSection && topOfferListingsSection.objects.length > 0 && (
                            <TopOffersSection topOfferListingsSection={topOfferListingsSection} url={url} />
                        )}
                        {!!typicalAccommodationsSection && typicalAccommodationsSection.items.length > 0 && (
                            <AccommodationSection typicalAccommodationsSection={typicalAccommodationsSection} />
                        )}
                        {popularFilterListingsSections.map((section, index) => (
                            <PopularFilterListingsSection
                                key={section.heading}
                                popularFilterListingsSection={section}
                                url={url}
                                sectionId={`objects-by-popular-filters-${index + 1}`}
                            />
                        ))}
                        {!!popularFiltersSection && popularFiltersSection.filters.length > 0 && (
                            <PopularObjectRedesign popularFiltersSection={popularFiltersSection} />
                        )}
                        {!!tips && tips.length > 0 && <TipsSection tips={tips} location={locationName} />}
                        {!!regionOverviewList && regionOverviewList.length > 0 && (
                            <RegionOverviewSection
                                faqs={faqs}
                                regionOverviewList={regionOverviewList}
                                location={locationName}
                            />
                        )}
                        {!!regionsSection && regionsSection.regions.length > 0 && (
                            <VacationRegionsSection regionsSection={regionsSection} />
                        )}
                        {!!holidayIdeasSection && holidayIdeasSection.items.length > 0 && (
                            <HolidayIdeasSection
                                holidayIdeasSection={holidayIdeasSection}
                                locationName={locationName}
                            />
                        )}
                    </InnerContainer>
                    <NewsLetter newsletter={newsLetterSection} url={url} />
                    {hasDirectorySectionWithLinks && (
                        <DirectorySection
                            region={directoryPageResponse?.regionName || ''}
                            directoryItems={directoryPageResponse?.sections || []}
                        />
                    )}
                    <FooterSection
                        footerSection={footerSection}
                        footerLogo={headerSection.navigationSectionResponse.navigation.logo}
                        socialMediaLinks={headerSection.navigationSectionResponse.socialMediaLinks}
                    />
                </Container>
            </main>
            <StickyFooter url={recommendedObjects?.cta?.url} />
        </Fragment>
    )
}

export default memo(LandingPage)
